import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89')
];

export const server_loads = [0,2,3,4,12,13,6,7,10];

export const dictionary = {
		"/(app)": [~14,[2]],
		"/(app)/driver": [15,[2,3]],
		"/(app)/driver/emergency": [16,[2,3]],
		"/(app)/driver/invoicing": [~17,[2,3]],
		"/(app)/driver/licenses": [18,[2,3]],
		"/(app)/driver/permits": [19,[2,3]],
		"/(app)/driver/vehicles": [20,[2,3]],
		"/(app)/events/(no-menu)": [~32,[2]],
		"/(app)/events/(menu)/[eventId]/(event)": [21,[2,4,5]],
		"/(app)/events/(menu)/[eventId]/(event)/documentation": [~22,[2,4,5]],
		"/(app)/events/(menu)/[eventId]/(event)/entrants": [~23,[2,4,5]],
		"/(app)/events/(menu)/[eventId]/(event)/fees": [24,[2,4,5]],
		"/(app)/events/(menu)/[eventId]/(event)/newsboard": [25,[2,4,5]],
		"/(app)/events/(menu)/[eventId]/(event)/noticeboard": [~26,[2,4,5]],
		"/(app)/events/(menu)/[eventId]/(registration)/register": [~31,[2,4]],
		"/(app)/events/(menu)/[eventId]/(event)/registrations": [27,[2,4,5]],
		"/(app)/events/(menu)/[eventId]/(event)/schedule": [28,[2,4,5]],
		"/(app)/events/(menu)/[eventId]/(event)/videos": [~29,[2,4,5]],
		"/(app)/events/(menu)/[eventId]/(event)/videos/[videoId]": [~30,[2,4,5]],
		"/(auth)/forgot-password": [66],
		"/(auth)/inactive": [67],
		"/(app)/join/chat/[chatId]": [~33,[2]],
		"/(auth)/login": [~68],
		"/(app)/onboarding": [34,[2]],
		"/public": [~73,[11]],
		"/public/events/(all-events)": [~74,[11]],
		"/public/events/[eventId]": [75,[11,12]],
		"/public/events/[eventId]/documentation": [~76,[11,12]],
		"/public/events/[eventId]/entrants": [~77,[11,12]],
		"/public/events/[eventId]/fees": [78,[11,12]],
		"/public/events/[eventId]/newsboard": [79,[11,12]],
		"/public/events/[eventId]/noticeboard": [80,[11,12]],
		"/public/events/[eventId]/schedule": [81,[11,12]],
		"/public/events/[eventId]/videos": [~82,[11,12]],
		"/public/events/[eventId]/videos/[videoId]": [~83,[11,12]],
		"/public/series/(all-series)": [~84,[11]],
		"/public/series/[seriesId]": [85,[11,13]],
		"/public/series/[seriesId]/documentation": [~86,[11,13]],
		"/public/series/[seriesId]/entrants": [~87,[11,13]],
		"/public/series/[seriesId]/fees": [88,[11,13]],
		"/public/series/[seriesId]/noticeboard": [89,[11,13]],
		"/(auth)/register": [69],
		"/(app)/registrations": [~35,[2]],
		"/(app)/registrations/[registrationId]": [36,[2,6]],
		"/(auth)/reset-password/[email]": [70],
		"/(app)/series/(no-menu)": [~44,[2]],
		"/(app)/series/(menu)/[seriesId]/(series)": [38,[2,7,8]],
		"/(app)/series/(menu)/[seriesId]/(series)/documentation": [~39,[2,7,8]],
		"/(app)/series/(menu)/[seriesId]/(series)/entrants": [~40,[2,7,8]],
		"/(app)/series/(menu)/[seriesId]/(series)/fees": [41,[2,7,8]],
		"/(app)/series/(menu)/[seriesId]/(series)/noticeboard": [~42,[2,7,8]],
		"/(app)/series/(menu)/[seriesId]/(registration)/register": [~37,[2,7]],
		"/(app)/series/(menu)/[seriesId]/(series)/registrations": [43,[2,7,8]],
		"/(app)/settings": [45,[2,9]],
		"/(app)/settings/data": [~46,[2,9]],
		"/(app)/settings/emergency": [~47,[2,9]],
		"/(app)/settings/guardians": [~48,[2,9]],
		"/(app)/settings/invoicing": [~49,[2,9]],
		"/(app)/settings/security": [50,[2,9]],
		"/(app)/settings/vehicle-preference": [~51,[2,9]],
		"/(app)/settings/verification": [~52,[2,9]],
		"/(app)/settings/verification/verify": [53,[2,9]],
		"/(app)/settings/wards": [~54,[2,9]],
		"/(app)/signatures": [~55,[2]],
		"/(app)/signatures/[entityId]/[registrationId]": [~56,[2]],
		"/(app)/teams": [~57,[2]],
		"/(app)/teams/[teamId]": [58,[2,10]],
		"/(app)/teams/[teamId]/invitations": [59,[2,10]],
		"/(app)/teams/[teamId]/invoicing": [~60,[2,10]],
		"/(app)/teams/[teamId]/licenses": [61,[2,10]],
		"/(app)/teams/[teamId]/members/drivers": [62,[2,10]],
		"/(app)/teams/[teamId]/members/managers": [63,[2,10]],
		"/(app)/teams/[teamId]/vehicles": [64,[2,10]],
		"/(auth)/verify": [71],
		"/(auth)/verify/[email]": [72],
		"/(app)/wards": [~65,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';